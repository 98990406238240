
import { defineComponent, ref, onMounted } from 'vue'
import SkeletonProductPage from '../loaders/SkeletonProductPage.vue'
import loading from '../../composables/loaders/loading'
import checkIfUserLoggedIn from '../../composables/user/checkIfUserLoggedIn'
import {getAndSetCustomer} from '../../composables/user/checkIfUserLoggedIn'
import modal from '../modal/ModalPopUp.vue'
import reportError from '../../../../global/js/utils/reportError'
import {enrollInProduct} from '../../../../global/js/utils/enrollmentHelper'
import {SlimProduct} from '../../../../global/js/utils/types/SlimProduct'

export type childProduct = { 'sku': string, 'rlmsId': string, title: string }
export default defineComponent({
  name: 'ProductDetailsPage',
  components: {
    SkeletonProductPage,
    modal,
  },
  props: {
    sku: {
      type: String,
      required: true,
    },
    rlmsId: {
      type: String,
      required: false,
      default: '',
    },
    children: {
      type: String,
      required: false,
      default: '[]',
    },
    isBundle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const selectors = {
      footerEl: 'footer',
      footerSpacingClass: 'activity-spacing',
    }
    const loaded = ref(false)
    const isModalVisible = ref(false)
    const errorTitle = ref('Error!')
    const errorMessage = ref('An error has occurred.')
    const isLoggedIn = ref(false)
    const childProducts = JSON.parse(props.children).map((child: childProduct) => {
      return {sku: child.sku, rlms_id: child.rlmsId, __typename: 'SimpleProduct'}
    })
    const product =
        {
          sku: props.sku,
          rlms_id: props.rlmsId,
          __typename: props.isBundle ? 'BundleProduct' : 'SimpleProduct',
          items: childProducts,
        } as SlimProduct

    onMounted(async (): Promise<void> => {
      loaded.value = false
      loading(true)
      localStorage.setItem('product', JSON.stringify(product))
      isLoggedIn.value = checkIfUserLoggedIn()
      if (isLoggedIn.value) {
        await getAndSetCustomer()
      }
      if (!props.isBundle) {
        footerSetup(selectors.footerSpacingClass, selectors.footerEl)
      }

      loading(false)
      loaded.value = true
    })

    function footerSetup(footerSpacingClass:string, footerSelector:string):void {
      const contentFooter = document.querySelector(footerSelector)

      if (contentFooter) {
        contentFooter?.classList.add(footerSpacingClass)
      }
    }

    const resetAndRedirectToLogin = () => {
      localStorage.setItem('returnUrl', window.location.href)
      localStorage.setItem('product', JSON.stringify(product))
      localStorage.removeItem('customer')
      localStorage.removeItem('fcme_transcript')
      loading(false)
      window.location.href = '/user/login'
    }

    async function startActivity(): Promise<void>{
      loading(true)

      if (isLoggedIn.value) {
        try {
          await enrollInProduct(product)
          const ssoUrl = localStorage.getItem('sso_url') as string

          if (ssoUrl.length) {
            const ssoTab = window.open(ssoUrl, product?.sku ?? 'rlpNewTab')

            if (!ssoTab) {
              errorTitle.value = 'One More Click'

              //new tab blocked
              errorMessage.value = `Oops! Your Popup Blocker is active, preventing the Course from opening. 
              <br />No problem, just <a href="${ssoUrl}">click the link</a> to unlock access to your FreeCME Course`

              isModalVisible.value = true
            }
            localStorage.removeItem('sso_url')
          } else {
            // user session timed out
            resetAndRedirectToLogin()
          }
        } catch (e: unknown) {
          errorMessage.value = reportError('START_ACTIVITY')
          isModalVisible.value = true
        } finally {
          loaded.value = true
          loading(false)
        }
      } else {
        resetAndRedirectToLogin()
      }
    }

    return {
      loaded,
      startActivity,
      isModalVisible,
      errorMessage,
      errorTitle,
    }
  },
})
