
import {computed, defineComponent, PropType, ref, onMounted, watch} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default defineComponent ({
  name: 'DropDownComponent',
  components: { FontAwesomeIcon },
  props: {
    dropdownPlaceholder: {
      type: String,
      default: '',
    },
    searchDropdownItems: {
      default: () => [],
      type: Array as PropType<Array<{label: string, value: string}>>,
    },
    searchDropdownSearchProperty: {
      type: String,
      default: 'label',
    },
    dropDownValues: {
      type: Array as PropType<Array<{label: string, value: string}>>,
      default: () => [],
    },
    dropdownLabelField: {
      type:String,
      default: '',
    },
    dropDownComponentName: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: [
    'setTouched',
    'setValue',
  ],
  setup(props, { emit }) {
    let isSelectActive = ref(false )
    const selectElement = ref<HTMLElement | null>(null )
    const searchQuery = ref('')
    const selectedItem = ref({
      label: '',
      value: '',
    })

    const selectLabel = ref(props.dropdownPlaceholder)

    const searchItems = computed(() => {
      if (searchQuery.value.length && props.searchDropdownItems !== undefined) {
        return props.searchDropdownItems.filter((item) => {
          return item.label.toLowerCase().indexOf(searchQuery.value.toLowerCase()) !== -1
        })
      }
      return props.searchDropdownItems
    })

    const setValue = (item: { label: string, value: string }) => {
      selectedItem.value = item
      selectLabel.value = item.label
      emit('setValue', selectedItem.value)
    }

    const dropdownToggleSelectState = (): void => {
      isSelectActive.value = !isSelectActive.value
    }

    const searchDropdownToggleSelectState = (): void => {
      if (isSelectActive.value) {
        emit('setTouched')
      }

      isSelectActive.value = !isSelectActive.value
    }

    window.addEventListener('click', (event: Event): void => {
      const target = event.target as HTMLElement

      if (!selectElement.value?.contains(target)) {
        if (isSelectActive.value) {
          emit('setTouched')
        }
        isSelectActive.value = false
      }
    })

    window.addEventListener('click', (event: Event): void => {
      const target = event.target as HTMLElement

      if (!selectElement.value?.contains(target)) {
        if (isSelectActive.value) {
          emit('setTouched')
        }
        
        isSelectActive.value = false
      }
    })

    watch(() => props.dropdownPlaceholder, (newValue) => {
      selectLabel.value = newValue
    })
    onMounted(async (): Promise<void> => {
      // Insert a non-line breaking white space if the placeholder is empty
      if (props.dropdownPlaceholder === '') {
        selectLabel.value = '\u00A0'
      } else {
        selectLabel.value = props.dropdownPlaceholder
      }
    })

    return {
      dropdownToggleSelectState,
      searchDropdownToggleSelectState,
      isSelectActive,
      selectElement,
      searchItems,
      setValue,
      searchQuery,
      selectedItem,
      selectLabel,
    }
  },
})


