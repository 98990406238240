import Customer from '../../../../global/js/magento-api-library/types/Customer'
import {getCustomer} from '../../../../global/js/magento-api-library/customer/getCustomer'
import {getN8nTranscripts} from '../../../../global/js/n8n-api-library/getTranscripts'

const checkIfUserLoggedIn = (): boolean => {
  try {
    if (localStorage.getItem('authToken')?.toString() != null && localStorage.getItem('authToken')?.toString() !== undefined) {
      const authToken = localStorage.getItem('authToken')?.toString()
      if (authToken !== undefined) {
        const userSession = JSON.parse(authToken) ?? null
        if (userSession !== null) {
          const currentTime = new Date()
          if (new Date(userSession?.expiration).valueOf() >= currentTime.valueOf()) {
            return true
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }

  clearLocalStorage()
  return false
}

const clearLocalStorage = (): void => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('customer')
  localStorage.removeItem('fcme_transcript')
}

export const getAndSetCustomer = async (getTranscript = false): Promise<void> => {
  const customer = JSON.parse(localStorage.getItem('customer') ?? '{}') as Customer
  const transcript = JSON.parse(localStorage.getItem('fcme_transcript') ?? '{}')
  if (!customer?.entity_id) {
    const customer = await getCustomer()
    localStorage.setItem('customer', JSON.stringify(customer))
  }
  if (getTranscript && customer.entity_id && !transcript?.length) {
    const transcript = await getN8nTranscripts({
      externalLearnerId: String(customer.entity_id ?? ''),
      // courseEnrollmentType: 1 or 2
      completed: 'true',
    })
    localStorage.setItem('fcme_transcript', JSON.stringify(transcript))
  }
}

export const getCustomerNameInitials = (): string => {
  const customer = JSON.parse(localStorage.getItem('customer') ?? '{}') as Customer

  if (customer.firstname && customer.lastname) {
    return `${customer.firstname.charAt(0)}` + `${customer.lastname.charAt(0)}`
  }

  return ''
}

export default checkIfUserLoggedIn